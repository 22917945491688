import Vue from 'vue';
import Router from 'vue-router';
import TransferETH from '../components/TransferETH.vue';
import NewPage from '../components/NewPage.vue'; // Import the new page component

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'TransferETH',
      component: TransferETH
    },
    {
      path: '/new-page',
      name: 'NewPage',
      component: NewPage
    }
  ]
});
