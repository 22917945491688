<template>
  <div class="eth-transfer">
    <div class="top-bar"></div>

    <img src="../assets/logo_nbx_v1.png" alt="nbx logo" class="logo">
    <!-- <div>NebulaX</div> -->
    <h1 style="color: white; position: absolute; top: 0px; left: 110px; font-style:italic;">Nebula X</h1>

    
    <transition name="modal">
      <div v-if="isModalOpen && !isMinimized" class="modal">
        <div class="modal-content">
          <!-- Minimize button only when transaction is ongoing -->
          <span v-if="isTransactionOngoing" class="minimize-button" @click="minimizeModal()">-</span>

          <!-- Close button only when transaction is not ongoing -->
          <span v-if="!isTransactionOngoing" class="close-button" @click="closeModal()">&times;</span>

          <p>{{ txMessage }}</p>
          <a v-if="txn1" :href="`${scanurl1}/tx/${txn1}`" target="_blank">{{ txn1.slice(0,6)+"..."+txn1.slice(-4) }}</a>
          <p></p>
          <p v-if="txn2"> {{ releaseTxMessage }} </p>
          <a v-if="txn2" :href="`${scanurl2}/tx/${txn2}`" target="_blank">{{ txn2.slice(0,6)+"..."+txn2.slice(-4) }}</a>
          <p></p>
          <div class="process-indicator" style="position:relative;">

            <template v-for="(step, index) in 5">
              <div class="step-wrapper" :key="`step-${index}`">
                <div class="process-step" :class="{
                  active: currentStep === index,
                  completed: currentStep > index
                }">
                  <template v-if="currentStep > index">✔</template>
                  <template v-else>?</template>
                </div>
                <div v-if="currentStep === index" class="circular-arrow"></div>
                <div class="arrow-dashed-line" v-if="index < 4" :class="{ 'moving-dashes': currentStep === index + 1 }">
                </div>
                <div class="arrow" v-if="index < 4"></div>
              </div>
              <div v-if="isExpanded" class="stage-info" :key="`stage-info-${index}`">
                Stage {{ index + 1 }}: {{ getStageDescription(index + 1) }}
              </div>
            </template>
            
          </div>
          <p></p>
          <button class="expand-button"
          :class="{ rotated: isExpanded }"
          @click="toggleExpand"
          ></button>



        </div>
      </div>

    </transition>

    <div v-if="isMinimized" :class="['minimized-icon', txStatus]" @click="restoreModal()">
      <template v-if="txStatus === 'success'">✔</template>
      <template v-else-if="txStatus === 'error'">?</template>
    </div>

    <div v-if="account" @click="disconnect" class="connect-wallet">
      <div class="connect-wallet-text">{{ account.slice(0, 6) + "..." + account.slice(-4) }}</div>
    </div>
    <div v-else @click="connect" class="connect-wallet">
      <div class="connect-wallet-text">Connect Wallet</div>
    </div>


    <!-- <div class="debug-section">
      <div @click="switchNetwork" style="color:orangered;">REFRESH</div>
      <p style="color: white;">Connected account:</p>
      <p style="color: white;">_   {{ account }}</p>
      <p style="color: white;">Current network:</p>
      <p style="color: white;">_   {{ currentNetwork }}</p>
      <p style="color: white;">Balance:</p> 
      <p style="color: white;">_   {{ balance }}</p> 
    </div> -->


    <div class="swap-section">
      <div class="Rectangle3">
        <div class="account-info">
          
          <div style="height: 30px;"></div>

          <div>
            <div class="from-div">
              <div class="from-to-text">From
                <select class="from-env" v-model="optionFrom">
                  <option v-for="(network, id) in fromNetworks" :value="id" :key="id">{{ network.chainName }}</option>
                </select>
                <div class="balance-text">Balance: {{ balance }} <img @click="switchNetwork" src="../assets/refresh.png" class="refresh-icon"></div>
              </div>
              
            </div>
            <div class="switch-from-to-button" @click="switchFromToButton">⇅</div>
            <div class="to-div">
              <div class="from-to-text">To
                <select class="from-env" v-model="optionTo" @change="toChainChanged">
                  <option v-for="(network, id) in toNetworks" :value="id" :key="id">{{ network.chainName }}</option>
                </select>
              </div>
            </div>
          </div>


          <div class="amount-text">Amount:
          </div>
          <div class="amount-input-wrapper">
            <input class="amount-input" type="text" v-model="amount" placeholder="Amount to send">
          <div class="max-button" @click="setToMax">MAX</div>
          </div>
          
          <p></p>

          <div class="estimate-received-text">
            Estimate Received: {{ estimatedReceived }}
            <!-- <div class="hint-wrapper">
              <span class="hint">?</span>
              <div class="tooltip">NebulaX takes constant 5% fee for all Testnets.</div>
            </div> -->
          </div> 

          <!-- When account is available -->
          <!-- When account is available and network needs to be switched -->

          <div style="height: 130px;"></div>

          <div v-if="account && !isTransactionOngoing && !shouldSwitchNetwork && isAmountValid && inputBalanceCheck" class="transfer-button" @click="switchNetwork">CHANGE NETWORK</div>

          <div v-else-if="account && !isTransactionOngoing && !inputBalanceCheck" class="transfer-button">Balance Insufficient</div>

          <div v-else-if="account && !isTransactionOngoing && !isAmountValid" class="transfer-button">Invalid Input</div>

          <!-- When account is available and can proceed with the transfer -->
          <div v-else-if="account && !isTransactionOngoing" class="transfer-button" @click="transferEth">TRANSFER</div>


          <!-- When no account is connected -->
          <div v-else class="transfer-button" @click="connect">Connect Wallet</div>
        </div>
      </div>
    </div>

  </div>
</template>

<style src="../css/styles.css"></style>

<script>
import { ethers } from 'ethers';
import { Web3 } from 'web3';
import axios from 'axios';
//import 'webcrypto-core'; // Import the polyfill
//import { SubtleCrypto } from 'crypto'; // Use the SubtleCrypto object


async function fetchWithRetry(url, maxRetries = 5, delay = 3000) {
  for (let i = 0; i < maxRetries; i++) {
    try {
      const response = await axios.get(url);
      return response;
    } catch (error) {
      if (i === maxRetries - 1) { // if last retry
        throw error;
      }
      console.warn(`Attempt ${i + 1} failed. Retrying in ${delay}ms...`);
      await new Promise(res => setTimeout(res, delay));
    }
  }
}

export default {
  data() {
    return {
      optionTo: null,
      currentNetwork: null,
      optionFrom: null,
      paramsMap: {},
      fromNetworks: {},
      toNetworks: {},
      account: null,
      recipient: '0x493E3eCC84D6673386E0dBEAfe45703C1656e6f9',
      amount: '0.1',
      balance: null,
      isModalOpen: false,
      isMinimized: false,
      isTransactionOngoing: false,
      txMessage: "Transaction in progress...",
      releaseTxMessage: "Releasing Txn2:",
      txStatus: null,  // null (no status), 'success', 'error'
      currentStep: 0,
      pollingInterval: null,
      isExpanded: false,
      txn1: null,
      txn2: null,
      scanurl1: "",
      scanurl2: "",
    };
  },
  methods: {
    padN(hash, n = 64) {
      if (hash.startsWith('0x')) {
        hash = hash.slice(2);
      }
      while (hash.length < n) {
        hash = "0" + hash;
      }
      return '0x' + hash;
    },
    async fetchNetwork() {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const network = await provider.getNetwork();
      const cid = parseInt(network.chainId);
      const foundNetwork = this.fromNetworks[cid] || this.toNetworks[cid];
      this.currentNetwork = foundNetwork ? foundNetwork.chainName : `Unknown Network (${cid})`;
      // console.log(this.optionFrom, foundNetwork, cid);
      this.optionFrom = cid;
      this.scanurl1 = this.paramsMap ? JSON.parse(JSON.stringify(this.paramsMap[cid].blockExplorerUrls))[0] : "";
      this.scanurl2 = this.paramsMap ? JSON.parse(JSON.stringify(this.paramsMap[this.optionTo].blockExplorerUrls))[0] : "";

      //refresh balance
      if (this.account) {
        const balanceWei = await provider.getBalance(this.account);
        this.balance = ethers.formatEther(balanceWei);
      }

    },
    setToMax() {
      if (this.balance) {
        this.amount = this.balance * 0.98;
        this.amount = String(+this.amount.toFixed(18));
      }
    },
    async switchNetwork() {
      const chainId = parseInt(this.optionFrom, 10);
      const hexChainId = '0x' + chainId.toString(16); // Convert to hex string
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: hexChainId }],
        });
        // Update the current network after switching
      } catch (switchError) {
        if (switchError.code === 4902) { // Chain not found
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [this.paramsMap[chainId]],
            });
            // Retry switching to the chain after adding
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: hexChainId }],
            });
          } catch (addError) {
            console.error('An error occurred while adding the network', addError);
          }
        } else {
          console.error('An error occurred while switching the network', switchError);
        }
      } finally {
        this.fetchNetwork();
      }
    },
    async switchFromToButton() {
      let tmp = this.optionFrom;
      this.optionFrom = this.optionTo;
      this.optionTo = tmp;
    },
    async toChainChanged() {
      console.log(this.optionTo);
    },
    async connect() {
      if (typeof window.ethereum !== 'undefined') {
        try {
          // Request account access
          const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
          this.account = accounts[0];
          this.fetchNetwork();
        } catch (error) {
          console.error("User denied account access");
        }
      } else {
        console.log('Non-Ethereum browser detected. Consider trying MetaMask!');
      }
    },
    async disconnect() {
      if (typeof window.ethereum !== 'undefined') {
        try {
          this.account = null;
          this.currentNetwork = null;
          console.log("User disconnected from the DApp.");
        } catch (error) {
          console.error("Error during disconnect:", error);
        }
      } else {
        console.log('No Ethereum provider detected.');
      }
    },
    async transferEth() {
      this.txn1 = null;
      this.txn2 = null;
      this.currentStep = null;
      this.isTransactionOngoing = true;
      this.txStatus = null; // Reset status on a new transaction
      await this.switchNetwork();

      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const selectedValue = Web3.utils.toHex(parseInt(this.optionTo, 10));

      const transaction = {
        to: this.recipient,
        value: ethers.parseEther(this.amount),
        data: this.padN(selectedValue, 10) + this.padN(this.account).slice(2),
      };

      // Open the modal before sending the transaction
      this.openModal("Waiting confirmation in Metamask");

      try {

        const tx = await signer.sendTransaction(transaction);
        this.startPolling();
        // this.startPolling();  // <-- Start polling here
        this.openModal("Txn1 Sent. Pending...");
        this.txn1 = tx.hash || tx.transactionHash;
        console.log(tx);
        this.currentStep = 1;
        await tx.wait();
        this.isTransactionOngoing = false; // set to false after transaction completes or fails

      } catch (error) {
        this.isTransactionOngoing = false; // also set to false in the catch block
        this.txStatus = 'error2';
        console.error("Error sending transaction", error);
        // this.openModal("Error sending transaction");
      }
    },
    openModal(message) {
      this.txMessage = message;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    minimizeModal() {
      this.isMinimized = true;
    },

    restoreModal() {
      this.isMinimized = false;
    },

    async startPolling() {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const network = await provider.getNetwork();
      // Check the API every 5 seconds (adjust the interval as needed)
      this.pollingInterval = setInterval(async () => {
        try {
          // const response = await axios.get(`http://54.179.64.225:3000/txStatus?chainId=${network.chainId}&txHash=${this.txn1}`);
          const response = await axios.get(`https://api.nbx.wtf/txStatus?chainId=${network.chainId}&txHash=${this.txn1}`);
          console.log(response.data,typeof response.data.step);

          if (response.data) {
            
            let tmp_currentstep = this.currentStep;
            if (tmp_currentstep < parseInt(response.data.step)) {
              this.currentStep = parseInt(response.data.step);

              if (response.data.release_txhash) {
                this.txn2 = response.data.release_txhash;
              }

              if (this.currentStep === 2) {
                this.txMessage = "Txn1 Pending"

              } else if (this.currentStep === 3) {
                this.txMessage = "Txn1 Success."

              } else if (this.currentStep === 4) {
                this.txMessage = "Txn1 Success.";
                this.releaseTxMessage = "Txn2 Pending";
                
              } else if (this.currentStep === 5) {
                this.stopPolling();
                this.txMessage = "Txn1 Success."
                this.releaseTxMessage = "Txn2 Success!";
              }

            }
          }
        } catch (error) {
          console.error('Error polling API:', error);
          this.txStatus = 'error';
          this.openModal('Error while checking the transaction status.');
          this.stopPolling();  // Stop polling on error from the API
        }
      }, 2000);
    },
    stopPolling() {
      clearInterval(this.pollingInterval);
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
    getStageDescription(step) {
      switch (step) {
        case 1: return "Transaction successful";
        case 2: return "Txn Received by NBX sequencer";
        case 3: return "Txn validity check OK";
        case 4: return "Releasing Txn Sent";
        case 5: return "Life cycle Finish";
        default: return "";
      }
    },
    

  },
  computed: {
    shouldSwitchNetwork() {
      // Assuming currentNetwork will store chainId. If not, adjust this logic.
      return parseInt(this.optionFrom, 10) !== this.currentNetwork;
    },
    estimatedReceived() {
      let result = (this.amount * 0.95).toFixed(6);
      // Remove unnecessary trailing zeros after the decimal point
      while (result.includes('.') && (result.endsWith('0') || result.endsWith('.'))) {
        result = result.slice(0, -1);
      }
      return result;
    },
    inputBalanceCheck() {
      if (this.amount <= this.balance * 0.98) {
        return true;
      }
      return false;
    },
    isAmountValid() {
      // Check if amount is within the range
      if (this.amount < 0.001 || this.amount > 1) {
        return false;
      }

      // Check if amount has no more than 18 decimal places
      const decimalPart = (this.amount + "").split('.')[1];
      if (decimalPart && decimalPart.length > 18) {
        return false;
      }

      return true;
    }
  },
  async mounted() {
    try {
      const response = await fetchWithRetry('https://1vnd62ejrk.execute-api.ap-southeast-1.amazonaws.com/env_config');
      this.paramsMap = response.data.paramsMap; // Assuming the whole response is your desired object
      this.fromNetworks = JSON.parse(JSON.stringify(response.data.fromNetworks));

      //应用to
      this.toNetworks = response.data.toNetworks;
      const networksKeys = Object.keys(this.toNetworks);
      const firstKey = networksKeys[0];
      this.optionTo = firstKey
      //

    } catch (error) {
      console.error("Error fetching paramsMap:", error);
    }

    this.fetchNetwork(); // Fetch the current network when the component is mounted

    window.ethereum.on('chainChanged', (chainId) => {
      console.log(chainId);
      this.fetchNetwork();
    });
  },
  
};
</script>

